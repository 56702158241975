import {get, del, put, post} from "aws-amplify/api";
import {fetchAuthSession} from "aws-amplify/auth";
import config from './config';
import {FULL_DATE} from "../tools/formatter";
import EventApiTranformer from '../tools/EventApiTranformer';

// TODO Why this doesn't work?
// async function requestData() {
//     return { // OPTIONAL
//         headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
//         response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
//         queryStringParameters: {}
//     }
// }

const EventsApi = {
    async getEvents(startDate, endDate)
    {
        return get({apiName: config.apiName, path: config.basePath, options: { // OPTIONAL
            headers: { Authorization: `Bearer  ${(await fetchAuthSession()).tokens.idToken.toString()}` },
            queryParams: {from: startDate.format(FULL_DATE), to: endDate.format(FULL_DATE)}
        }}).response.then(response => response.body.json()).catch(error => {
            console.log('Error', error);
        });
    },

    async getEvent(id)
    {
        console.log('getting event');
        let path = config.basePath + id;
        return get({apiName: config.apiName, path: path, options: { // OPTIONAL
            headers: { Authorization: `Bearer  ${(await fetchAuthSession()).tokens.idToken.toString()}` }
        }}).response.then(response => response.body.json()).catch(error => {
            console.log(error.response)
        });
    },

    async deleteEvent(id)
    {
        let path = config.basePath + id;
        return del({apiName: config.apiName, path: path, options: { // OPTIONAL
            headers: { Authorization: `Bearer  ${(await fetchAuthSession()).tokens.idToken.toString()}` }
        }}).response.then(response => response.body.json()).catch(error => {
            console.log(error.response)
        });
    },

    async updateEvent(currentEvent)
    {
        let path = config.basePath + currentEvent.id;
        let apiEvent = EventApiTranformer.toApi(currentEvent);
        let myInit = { // OPTIONAL
            headers: { Authorization: `Bearer  ${(await fetchAuthSession()).tokens.idToken.toString()}` },
            body: apiEvent
        };
        return put({apiName: config.apiName, path: path, options: myInit}).response.then(response => response.body.json()).catch(error => {
            console.log(error.response)
        }).catch(error => {
            //TODO catch
        });
    },

    async addEvent(newEvent)
    {
        let apiEvent = EventApiTranformer.toApi(newEvent);
        let myInit = { // OPTIONAL
            headers: { Authorization: `Bearer  ${(await fetchAuthSession()).tokens.idToken.toString()}` },
            body: apiEvent,

        };
        return post({apiName: config.apiName, path: config.basePath, options: myInit}).response.then(response => response.body.json()).catch(error => {
            console.log(error.response)
        }).catch(error => {
            //TODO catch
        });
    },

    async getUserEvent(userId, eventId)
    {
        let path = config.basePath + 'events/' + userId + '/' + eventId;
        return get({apiName: config.apiName, path: path, options: { // OPTIONAL
            headers: { }
        }}).response.then(response => response.body.json()).catch(error => {
            console.log(error.response)
        });
    },

    async getImagesStats()
    {
        console.log('getting stats');
        let path = config.basePath + '/imagesstats';
        return get({apiName: config.apiName, path: path, options: { // OPTIONAL
            headers: { Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken.toString()}` },
            queryStringParameters: {}
        }}).response.then(response => {
            return response.body.json();
        }).catch(error => {
            console.log(error.response)
        });
    },
}
export default EventsApi;
