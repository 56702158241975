import React from 'react';
import './YearItem.scss';
import {connect} from "react-redux";
import Counters from "../../Counters/Counters";

function YearItem(props) {
	const {item} = props;
	return <div className={'YearItem DiaryItem'}>
		<div className={"Header" + (parseInt(props.currentNavigationYear) === item.eventsYear ? " active" : "")}>{item.eventsYear}</div>
		<div className={"separator"} ></div>
		<Counters item={item} />
	</div>;
}

const mapStateToProps = state => {
	return {
		currentNavigationYear: state.eventsData.currentNavigationYear
	};
};

export default connect(mapStateToProps)(YearItem);
