import React from 'react';
import './EventItem.scss';
import {connect} from "react-redux";

function EventItem(props) {
	const {item} = props;
	return <div className={'EventItem DiaryItem' + (props.currentNavigationEventId === item.eventPath ? " active" : "")}>
		<div className={'Header'}>{item.eventName}</div>
		<div className={'ImagesCount'}><p>&#128444;&nbsp;{item.imagesCount}</p></div>
		<div className={'VideosCount'}>&#128249;&nbsp;{item.videosCount}</div>
	</div>;
}

const mapStateToProps = state => {
	return {
		currentNavigationEventId: state.eventsData.currentNavigationEventId
	};
};

export default connect(mapStateToProps)(EventItem);
