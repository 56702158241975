import {get} from "aws-amplify/api";
import config from './config-all-events';

const AllEventsApi = {
    async getByYears(userId)
    {
        return get({apiName: config.apiName, path: config.basePath + 'all', options: { // OPTIONAL
            queryParams: {userId: userId}
        }}).response.then(response => response.body.json()).catch(error => {
            console.log('Error', error);
        });
    },

    async getByMonths(userId, year)
    {
        return get({apiName: config.apiName, path: config.basePath + 'year', options: { // OPTIONAL
            queryParams: {userId: userId, year: year}
        }}).response.then(response => response.body.json()).catch(error => {
            console.log('Error', error);
        });
    },

    async getByDays(userId, year, month)
    {
        return get({apiName: config.apiName, path: config.basePath + 'month', options: { // OPTIONAL
            queryParams: {userId: userId, year: year, month: month}
        }}).response.then(response => response.body.json()).catch(error => {
            console.log('Error', error);
        });
    },

    async getDay(userId, year, month, day)
    {
        return get({apiName: config.apiName, path: config.basePath + 'day', options: { // OPTIONAL
            queryParams: {userId: userId, year: year, month: month, day: day}
        }}).response.then(response => response.body.json()).catch(error => {
            console.log('Error', error);
        });
    }
}
export default AllEventsApi;
