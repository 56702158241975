import React, {useEffect, useState} from 'react';
import "./AllEventsDays.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";
import MonthItem from "../../EventsByMonth/MonthItem/MonthItem";
import {DAY_NAME, DAY_NUMBER, FULL_DATE, getCalendarMonth, getMonthName} from "../../../../tools/formatter";
import moment from "moment";
import {Link} from "react-router-dom";
import DayItem from "../../EventsByDay/DayItem/DayItem";
import UrlManager from "../../../../tools/UrlManager";
import Loading from "../../../../components/Loading/Loading";

function AllEventsDays(props) {
    const [days, setDays] = useState([]);
    const [headerText, setHeaderText] = useState('');
    const calendarHeader = [...Array(7).keys()].map(d =>
        <div className={"DiaryItemWrapper DayItemWrapper DayItemHeader"}>{moment().day(d).format(DAY_NAME)}</div>
    );
    const setCurrentNavigationDay = (navigationDay) => {
        props.onUpdateCurrentNavigationDay(navigationDay);
    }


    useEffect(() => {
        setDays([]);
        if ( props.userId && props.currentNavigationYear && props.currentNavigationMonth ) {
            const year = props.currentNavigationYear;
            const month = props.currentNavigationMonth;
            let allDays = getCalendarMonth(year, month);
            allDays = allDays.map(day => {return {'dayDate': day, 'dayInfo': null}});
            AllEventsApi.getByDays(props.userId, year, month).then(response => {
                response.rows.forEach(row => {
                    let key = moment(year + '-' + month + '-' + row.eventsDay).format(FULL_DATE);
                    let index = allDays.findIndex(object => object.dayDate.format(FULL_DATE) === key);
                    if(index > -1) {
                        allDays[index].dayInfo = row;
                    } else {
                        console.log('Cannot find index!', key, allDays);
                    }
                    allDays[key] = row;
                });
                setDays(allDays);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationYear, props.currentNavigationMonth]);

    useEffect(() => {
        if ( props.currentNavigationDay ) {
            setHeaderText(props.currentNavigationDay);
        }
    }, [props.currentNavigationDay]);


    return props.userId && props.currentNavigationYear && props.currentNavigationMonth ? <DiaryWrapper
        additionalClassName={"DaysWrapper"}
        isContentSelected={props.userId && props.currentNavigationYear && props.currentNavigationMonth && props.currentNavigationDay}
        headerText={headerText}
        defaultHeaderText={"Please choose day"}
    >{days.length > 0 ? calendarHeader : ''}{days.length > 0 ? days.map((dayObject, index) => {
        return (
            <div className={"DiaryItemWrapper DayItemWrapper " + (dayObject.dayInfo === null ? ' Empty' : '')} key={index}  onClick = {() => {
                setHeaderText(dayObject.dayDate.format(DAY_NUMBER));
                const currentNavigationDay = dayObject.dayDate.date().toString();
                setCurrentNavigationDay(currentNavigationDay);
                UrlManager.setUrl(props.userId, props.currentNavigationYear, props.currentNavigationMonth, currentNavigationDay);
            }}>
                <DayItem item={dayObject.dayInfo} itemDate={dayObject.dayDate}></DayItem>
            </div>
        )
    }) : <Loading />}</DiaryWrapper> : '';
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear,
        currentNavigationMonth: state.eventsData.currentNavigationMonth,
        currentNavigationDay: state.eventsData.currentNavigationDay
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationDay: (currentNavigationDay) => dispatch(actionCreators.updateNavigationDay(currentNavigationDay))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsDays);
