import React, {useEffect, useState} from 'react';
import "./AllEventsYears.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import YearItem from "../../EventsByYear/YearItem/YearItem";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";
import UrlManager from "../../../../tools/UrlManager";
import Loading from "../../../../components/Loading/Loading";

function AllEventsYears(props) {
    const [years, setYears] = useState([]);
    const [headerText, setHeaderText] = useState('');

    const setCurrentNavigationYear = (navigationYear) => {
        props.onUpdateCurrentNavigationYear(navigationYear);
    }


    useEffect(() => {
        if ( props.userId ) {
            AllEventsApi.getByYears(props.userId).then(response => {
                console.log(response);
                setYears(response.rows);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId]);

    useEffect(() => {
        if ( props.currentNavigationYear ) {
            setHeaderText(props.currentNavigationYear);
        }
    }, [props.currentNavigationYear]);


    return <DiaryWrapper
        additionalClassName={"YearsWrapper"}
        headerText={headerText}
        defaultHeaderText={"Please choose year"}
        isContentSelected={props.userId && props.currentNavigationYear}
    >
        {years.length > 0 ? years.map(yearInfo => {
        return (
            <div key={yearInfo.eventsYear}  onClick = {() => {
                setCurrentNavigationYear(yearInfo.eventsYear);
                UrlManager.setUrl(props.userId, yearInfo.eventsYear);
            }}>
                <YearItem item={yearInfo} />
            </div>
        )
    }) : <Loading />}</DiaryWrapper>;
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationYear: (currentNavigationYear) => dispatch(actionCreators.updateNavigationYear(currentNavigationYear))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsYears);
