import React from "react";
import './AppHeader.scss';

const AppHeader = () => {
	return <div className={"AppHeader AppHeader_guest"}>
		<img alt={"Logo"} className={"logo"} src={"/logo.svg"} />
	</div>;
}

export default AppHeader;
