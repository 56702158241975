import React, {useEffect, useState} from 'react';
import "./AllEventsForDay.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";
import EventItem from "../../EventsForDay/EventItem/EventItem";
import Loading from "../../../../components/Loading/Loading";
import UrlManager from "../../../../tools/UrlManager";

function AllEventsForDay(props) {
    const [dayEvents, setDayEvents] = useState([]);
    const [headerText, setHeaderText] = useState([]);

    const setCurrentNavigationEventId = (eventId) => {
        props.onUpdateCurrentNavigationEventId(eventId);
    }

    useEffect(() => {
        setDayEvents([]);
        if ( props.userId && props.currentNavigationYear && props.currentNavigationMonth && props.currentNavigationDay ) {
            const year = props.currentNavigationYear;
            const month = props.currentNavigationMonth;
            const day = props.currentNavigationDay;
            AllEventsApi.getDay(props.userId, year, month, day).then(response => {
                console.log(response);
                setDayEvents(response.rows);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationYear, props.currentNavigationMonth, props.currentNavigationDay]);

    useEffect(() => {
        console.log('Current event', props.currentEvent);
        if ( props.currentEvent ) {
            setHeaderText(props.currentEvent.title);
        }
    }, [props.currentEvent]);

    return props.userId && props.currentNavigationYear && props.currentNavigationMonth && props.currentNavigationDay  ? <DiaryWrapper
        isContentSelected={props.userId && props.currentNavigationYear && props.currentNavigationMonth && props.currentNavigationDay && props.currentNavigationEventId}
        headerText={headerText}
        defaultHeaderText={"Please choose event"}
        additionalClassName={"EventsWrapper"}
    >{dayEvents.length > 0 ? dayEvents.map((dayInfo) => {
        return (
            <div key={dayInfo.eventPath}  onClick = {() => {
                setHeaderText(dayInfo.eventName);
                setCurrentNavigationEventId(dayInfo.eventPath);
                UrlManager.setUrl(props.userId, props.currentNavigationYear, props.currentNavigationMonth, props.currentNavigationDay, dayInfo.eventPath);
            }}>
                <EventItem item={dayInfo} />
            </div>
        )
    }) : <Loading />}</DiaryWrapper> : '';
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear,
        currentNavigationMonth: state.eventsData.currentNavigationMonth,
        currentNavigationDay: state.eventsData.currentNavigationDay,
        currentNavigationEventId: state.eventsData.currentNavigationEventId,
        currentEvent: state.eventsData.currentEvent
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationEventId: (currentEventId) => dispatch(actionCreators.updateNavigationEventId(currentEventId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsForDay);
