import React, {useEffect, useState} from 'react';
import "./AllEventsSingleEvent.scss";
import EventsApi from "../../../../api/EventsApi";
import {getAlbumPath} from "../../../../tools/formatter";
import DiaryWrapper from "../../DiaryWrapper";
import ReactImageGallery from "../../../../components/ReactImageGallery/ReactImageGallery";
import {connect} from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";
import Loading from "../../../../components/Loading/Loading";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import YouTubeWidget from "../../../../components/YouTube/YouTubeWidget";

function AllEventsSingleEvent(props) {

    const [value, setValue] = React.useState('photo');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        console.log('componentdidmount single event');
        if ( props.userId && props.currentNavigationEventId) {
            EventsApi.getUserEvent(props.userId, props.currentNavigationEventId).then(response => {
                console.log('EVENT: ', response);
                props.onGetCurrentEvent(response.event);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationEventId]);

    return props.userId && props.currentNavigationEventId ?
        props.currentEvent ?
            <div className={"DiaryContainer"}>
                <Box sx={{width: '100%', typography: 'body1'}}>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Photo" value="photo"/>
                                <Tab label="Video" value="video"/>
                            </TabList>
                        </Box>
                        <TabPanel value="photo">
                            <div>
                                <div className={"album-block"}>
                                    <ReactImageGallery path={getAlbumPath(props.currentEvent)}/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value="video">
                            {props.currentEvent.video.length > 1 ? <div>
                                {props.currentEvent.video.split(',').map(video => {
                                    return <div className={"video"}>
                                        <YouTubeWidget key={video} embedId={video}/>
                                    </div>
                                })}
                            </div> : 'No video'}
                        </TabPanel>
                    </TabContext>
                </Box>
            </div> : <Loading/>
     : '';
}

const mapStateToProps = state => {
    return {
        currentNavigationEventId: state.eventsData.currentNavigationEventId,
        currentEvent: state.eventsData.currentEvent
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCurrentEvent: (currentEvent) => dispatch(actionCreators.getEvent(currentEvent))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsSingleEvent);
