import React from 'react';
import './MonthItem.scss';
import {getMonthName} from "../../../../tools/formatter";
import {connect} from "react-redux";
import Counters from "../../Counters/Counters";

function MonthItem(props) {
	const {item, monthNumber} = props;
	return <div className={'MonthItem DiaryItem'}>
		<div className={'Header' + (monthNumber === parseInt(props.currentNavigationMonth) ? ' active' : '')}>{getMonthName(window.navigator.language, monthNumber)}</div>
				<div className={"separator"}></div>
				<Counters item={item} />
	</div>;
}

const mapStateToProps = state => {
	return {
		currentNavigationMonth: state.eventsData.currentNavigationMonth
	};
};

export default connect(mapStateToProps)(MonthItem);
