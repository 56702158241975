import React from 'react';
import './GuestApp.scss';
import '@aws-amplify/ui-react/styles.css';
import Diary from "./Diary/Diary";

const GuestApp = () => {
    return <Diary />;
}

export default GuestApp;
