import EventsStorage from "../../storage/EventsStorage";
import {useEffect, useState} from "react";
import './ReactImageGallery.scss';
import config from "../../api/config";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loading from "../Loading/Loading";

const ReactImageGallery = (props) => {
	const [items, setItems] = useState([]);
	const [areItemsLoaded, setAreItemsLoaded] = useState(false);
	const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);

	const getItems = (path) => {
		EventsStorage.listFiles(path).then((imgItems) => {
			setItems(imgItems.map(item => {
				return {
					original: item.path,
					thumbnail: config.thumbnailLambdaUrl + '?key=/' + item.key
				}
			}));
			console.log(items);
			setAreItemsLoaded(true);
			if (imgItems.length === 0) {
				setIsFirstImageLoaded(true);
			}
		});
	}

	useEffect(() => {
		setIsFirstImageLoaded(false);
		setAreItemsLoaded(false);
		if (props.path) {
			getItems(props.path);
		}
	}, [EventsStorage.listFiles, props.path]);

	return (<div className={'gallery'}>
		{areItemsLoaded && isFirstImageLoaded ? '' : <Loading />}
		{areItemsLoaded ? (items.length > 0 ? <div className={isFirstImageLoaded ? '' : 'hidden'}><ImageGallery onImageLoad = {() => setIsFirstImageLoaded(true)} items={items} /></div> : 'No photo') : ''}
	</div>)
};

export default ReactImageGallery;