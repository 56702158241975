import React, {useEffect, useState} from 'react';
import "./AllEventsMonths.scss";
import AllEventsApi from "../../../../api/AllEventsApi";
import DiaryWrapper from "../../DiaryWrapper";
import * as actionCreators from "../../../../store/actions/actions";
import {connect} from "react-redux";
import MonthItem from "../../EventsByMonth/MonthItem/MonthItem";
import {getMonthName} from "../../../../tools/formatter";
import UrlManager from "../../../../tools/UrlManager";
import Loading from "../../../../components/Loading/Loading";

function AllEventsMonths(props) {
    const [months, setMonths] = useState([]);
    const [headerText, setHeaderText] = useState('');

    const setCurrentNavigationMonth = (navigationMonth) => {
        props.onUpdateCurrentNavigationMonth(navigationMonth);
    }


    useEffect(() => {
        if ( props.userId && props.currentNavigationYear ) {
            setMonths([]);
            AllEventsApi.getByMonths(props.userId, props.currentNavigationYear).then(response => {
                let allMonths = new Array(13).fill(null);
                response.rows.forEach(month => {
                    allMonths[month.eventsMonth] = month;
                });
                setMonths(allMonths);
            } ).catch(error => {
                //TODO catch
            });
        }
    }, [props.userId, props.currentNavigationYear]);

    useEffect(() => {
        if ( props.currentNavigationMonth ) {
            setHeaderText(getMonthName(window.navigator.language, props.currentNavigationMonth));
        }
    }, [props.currentNavigationMonth]);


    return props.userId && props.currentNavigationYear ? <DiaryWrapper
        additionalClassName={"MonthsWrapper"}
        isContentSelected={props.userId && props.currentNavigationYear && props.currentNavigationMonth}
        headerText={headerText}
        defaultHeaderText={"Please choose month"}
    >{months.length > 0 ? months.map((monthInfo, index) => {
        return (
            index > 0 ?
                <div key={index}  onClick = {() => {
                    setHeaderText(getMonthName(window.navigator.language, index));
                    setCurrentNavigationMonth(index);
                    UrlManager.setUrl(props.userId, props.currentNavigationYear, index);
                }}>
                    <MonthItem item={monthInfo}  monthNumber={index} />
                </div> : ''
        )
    }) : <Loading />}</DiaryWrapper> : '';
}

const mapStateToProps = state => {
    return {
        currentNavigationYear: state.eventsData.currentNavigationYear,
        currentNavigationMonth: state.eventsData.currentNavigationMonth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationMonth: (currentNavigationMonth) => dispatch(actionCreators.updateNavigationMonth(currentNavigationMonth))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEventsMonths);
