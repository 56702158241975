import React from 'react';
import './DayItem.scss';
import {DAY_NUMBER} from "../../../../tools/formatter";
import {connect} from "react-redux";
import Counters from "../../Counters/Counters";

function DayItem(props) {
	const {item, itemDate} = props;
	return <div className={'DayItem DiaryItem'}>
		<div className={'Header'+ (props.currentNavigationDay === itemDate.date().toString() ? " active" : "")}>{itemDate.format(DAY_NUMBER)}</div>
		{ item !== null ? <Counters item={item} /> : "" }
	</div>;
}

const mapStateToProps = state => {
	return {
		currentNavigationDay: state.eventsData.currentNavigationDay
	};
};

export default connect(mapStateToProps)(DayItem);
