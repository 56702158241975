import { ThemeProvider } from '@aws-amplify/ui-react';
import App from "./App";
import GuestApp from "./GuestApp";
import { useAuthenticator } from '@aws-amplify/ui-react';

const AppWithAuth = (props) => {
    const { authStatus  } = useAuthenticator(context => [context.authStatus]);
    return (
        <ThemeProvider>
            {authStatus === 'configuring' && 'Loading...'}
            {authStatus === 'authenticated' && <App {...props}/>}
            {authStatus === 'unauthenticated' && <GuestApp />}
        </ThemeProvider>
    );
}
export default AppWithAuth;
