const UrlManager = {
	setUrl: (userId, year, month, day, eventId) => {
		let url = '/' + userId;
		if (eventId) {
			url += '/events/' + eventId;
		} else if (year) {
			url += '/' + year;
			if (month) {
				url += '/' + month;
				if (day) {
					url += '/' + day;
				}
			}
		}
		window.history.pushState(null, '', url);
	},
	getDateFromEventId: (eventId) => {
		const eventDate = eventId.substring(0, 10).split('-');
		return {
			year: eventDate[0],
			month: eventDate[1],
			day: eventDate[2]
		};
	}
}

export default UrlManager;