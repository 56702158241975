import React from 'react';
import './DiaryWrapper.scss';
import {useState, useEffect} from "react";

function DiaryWrapper(props) {
	const [isContentOpened, setIsContentOpened] = useState(true);

	const handleClickOpen = () => {
		setIsContentOpened(!isContentOpened);
	};

	useEffect(() => {
			setIsContentOpened(!props.isContentSelected);
		},
		[props.isContentSelected]);

	return <div className={`DiaryContainer ${props.additionalClassName}`}>
		<div className={'DiaryHeader'}>{props.isContentSelected ? props.headerText : props.defaultHeaderText} {props.isContentSelected ? <img className={"img-btn"} alt={"Open content	"} src={isContentOpened ? "/angle-down.svg" : "/angle-right.svg"} onClick={handleClickOpen}></img> : ''}</div>
		{isContentOpened ? <div className={'DiaryContent'}>{props.children}</div> : ''}
	</div>;
}

export default DiaryWrapper;
