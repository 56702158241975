import React, {useEffect} from 'react';
import "./AllEvents.scss";
import {useParams} from "react-router-dom";
import AllEventsYears from "./AllEventsYears/AllEventsYears";
import AllEventsMonths from "./AllEventsMonths/AllEventsMonths";
import AllEventsDays from "./AllEventsDays/AllEventsDays";
import AllEventsForDay from "./AllEventsForDay/AllEventsForDay";
import AllEventsSingleEvent from "./AllEventsSingleEvent/AllEventsSingleEvent";
import {connect} from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import AppHeader from "../../AppHeader/AppHeader";
import UrlManager from "../../../tools/UrlManager";

function AllEvents(props) {
    const { userId, year, month, day, eventId } = useParams();

    useEffect(() => {
        if (year) {
            props.onUpdateCurrentNavigationYear(year);
        }
    }, [year]);

    useEffect(() => {
        if (month) {
            props.onUpdateCurrentNavigationMonth(month);
        }
    }, [month]);

    useEffect(() => {
        if (day) {
            props.onUpdateCurrentNavigationDay(day);
        }
    }, [day]);

    useEffect(() => {
        if (eventId) {
            const eventDate = UrlManager.getDateFromEventId(eventId);
            props.onUpdateCurrentNavigationYear(eventDate.year);
            props.onUpdateCurrentNavigationMonth(eventDate.month);
            props.onUpdateCurrentNavigationDay(eventDate.day);
            props.onUpdateCurrentNavigationEventId(eventId);
        }
    }, [eventId]);

    return <div>
        <div className={'AllEventsHeader'}>
            <AppHeader />
        </div>
        <AllEventsYears userId={userId} />
        <AllEventsMonths userId={userId} />
        <AllEventsDays userId={userId} />
        <AllEventsForDay userId={userId} />
        <AllEventsSingleEvent userId={userId} />
    </div>
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCurrentNavigationYear: (currentNavigationYear) => dispatch(actionCreators.updateNavigationYear(currentNavigationYear)),
        onUpdateCurrentNavigationMonth: (currentNavigationMonth) => dispatch(actionCreators.updateNavigationMonth(currentNavigationMonth)),
        onUpdateCurrentNavigationDay: (currentNavigationDay) => dispatch(actionCreators.updateNavigationDay(currentNavigationDay)),
        onUpdateCurrentNavigationEventId: (currentNavigationEventId) => dispatch(actionCreators.updateNavigationEventId(currentNavigationEventId))
    }
};

export default connect(null, mapDispatchToProps)(AllEvents);
