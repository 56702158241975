import React from "react";
import './Counters.scss';

function Counters(props) {
	return <div className={"Counters"}>
		<div className={'EventsCount ItemCount'}><img src={'/hugeicons--calendar-03.svg'}
											alt={'Events count'}/>&nbsp;{props.item ? props.item.eventsCount : 0}</div>
		<div className={'ImagesCount ItemCount'}><img src={'/hugeicons--image-02.svg'}
											alt={'Images count'}/>&nbsp;{props.item ? props.item.imagesCount : 0}</div>
		<div className={'VideosCount ItemCount'}><img src={'/hugeicons--video-02.svg'}
											alt={'Videos count'}/>&nbsp;{props.item ? props.item.videosCount : 0}</div>
	</div>;
}

export default Counters;