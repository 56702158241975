import { list, getUrl } from 'aws-amplify/storage';

const EventsStorage = {
	listFiles: function (bucket) {
		console.log(bucket);
		return list({path: 'public/' + bucket,  options: {
				listAll: true,
			}}) // for listing ALL files without prefix, pass '' instead
			.then(result => {
				return Promise.all(
					result.items.map(async r => {
						return {
							key: r.path,
							path: (await getUrl({key: r.path.substring(7)})).url // subscring(7) for remove duplicated '/public'. Why it is duplicated???
						}
					})
				);
			});
	}
};

export default EventsStorage;