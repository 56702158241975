import * as actionCreators from "../../store/actions/actions";
import {connect} from "react-redux";
import {useEffect} from "react";
import EventsApi from "../../api/EventsApi";
import './ImagesStats.scss';

function ImagesStats(props) {
	const onGetImagesStats = props.onGetImagesStats;
	const sizes = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb']
	const formatStats = bytes => {
		let val = parseFloat(bytes);
		for(let i = 0; i < sizes.length; i++) {
			if (val < 100) {
				return val.toFixed(2).toString() + ' ' + sizes[i];
			}
			val = val / 1024;
		}
		return val.toFixed(2).toString() + ' ' + sizes[sizes.length - 1];
	}

	useEffect(() => {

		EventsApi.getImagesStats().then(response => {
			console.log('final response', response);
			onGetImagesStats(response.imagesstats);
		} ).catch(error => {
			//TODO catch
		});

	}, [onGetImagesStats]);

	return (<div className={"imagesStats-container"}>
		Used: {props.imagesStats ? formatStats(props.imagesStats.imagessize) : '?'}
	</div>)
}

const mapStateToProps = state => {
	return {
		imagesStats: state.eventsData.imagesStats
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetImagesStats: (currentImagesStats) => dispatch(actionCreators.getImagesStats(currentImagesStats))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesStats);